import angular from 'angular';

import dateToIso from './date-to-iso';
import dayFilter from './day-filter';
import filterObjectFilter from './filter-object-filter';
import ucWordsFilter from './uc-words-filter';

export default angular
  .module('PartnerChoice.common.filter', [])
  .filter('dateToIso', dateToIso)
  .filter('day', dayFilter)
  .filter('filterObject', filterObjectFilter)
  .filter('ucWords', ucWordsFilter);
