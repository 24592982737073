import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, distinct, Observable} from 'rxjs';
import {CommonService} from './common.service';
import {environment} from '../../environment';

@Injectable()
export class AuthService {
  private isAuth: BehaviorSubject<boolean | string | null> =
    new BehaviorSubject(false);
  public isAuthenticated: Observable<boolean | string | null> =
    this.isAuth.pipe(distinct());

  constructor(
    public jwtHelper: JwtHelperService,
    public router: Router,
    private commonService: CommonService
  ) {
    this.isAuth.next(this.isLoggedIn());
  }

  public isLoggedIn() {
    const token = this.getToken();

    return token && token !== 'null' ;
  }

  public tokenChanged() {
    this.isAuth.next(this.isLoggedIn());
  }

  getToken() {
    const token = localStorage.getItem('partnerchoice_api_token');
    if (token !== 'null' && this.jwtHelper.isTokenExpired(token)) {
      this.logout();
    }

    return token;
  }

  getLeisureToken() {
    const token = localStorage.getItem('AuthToken');
    return token && JSON.parse(token);
  }

  async logout() {
    await localStorage.setItem('partnerchoice_api_token', null);
    await localStorage.setItem('partnerchoice_api_refresh_token', null);
    await localStorage.setItem('AuthToken', null);
    this.commonService.navigate('/Login');
    this.isAuth.next(this.isLoggedIn());
  }
}

export function tokenGetter(request?: any) {
  if (request && request.url.includes(environment.settings.leisureUrl)) {
    var token = localStorage.getItem('AuthToken');
    return token && JSON.parse(token);
  } else if (
    request &&
    request.url.includes(environment.settings.registeredAwsUrl)
  ) {
    return localStorage.getItem('partnerchoice_api_token');
  }
  return null;
}
